import { errorNavigator } from "@common/utils/error-navigator";
import { Collaborator, Markup, Project } from "@server/entities/project";
import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { Route, Routes, useParams } from "react-router";
import { StemViewer } from "../../stemviewer";
import { joinSharedProject } from "src/requests/project/project";
import { KeyedMutator, SWRResponse } from "swr";
import { ProjectView } from "./ProjectView";
import { useResetRecoilState, useSetRecoilState } from "recoil";
import { svState } from "../../stemviewer/recoil/stemviewer.selector";
import { useUser } from "../../../contexts/UserContext";
import { withAuthConditional } from "../../routes/ProtectedRoute";
import {
  getMarkups,
  getMyMarkups,
  useMarkups,
} from "../../../requests/project/markup";
import { useDeepEffect } from "@common/utils/use-deep-effect";
import { StringParam, useQueryParams } from "use-query-params";
import { notification } from "@common/utils/notification";
import { browserName } from "react-device-detect";
import { projectState } from "../../../contexts/Tasks/task.atom";
import { useProject as useProjectData } from "../../../requests/project/project";

export interface ProjectContextType {
  loading: boolean;
  project?: Project;
  mutate: KeyedMutator<{ project?: Project; role?: Collaborator["role"] }>;
  markups: Markup[];
  mutateMarkups: KeyedMutator<any>;
  view: Collaborator["role"];
  currMixdownId: string | null;
  setCurrMixdownId: Dispatch<SetStateAction<string | null>>;
}

const initialState = {} as ProjectContextType;

const Context = createContext<ProjectContextType>(initialState);
Context.displayName = "ProjectContext";

export const useProject = () => useContext(Context);

export const fetchMarkups = async (project?: Project) => {
  if (project?.id) return getMarkups(project.id, { type: "markup" });
  else return getMyMarkups({ type: "markup", assignedToMe: true });
};

export const ProjectContext = () => {
  const isMobile = window.innerWidth < 768;
  const isSafari = browserName.includes("Safari");

  const setSvState = useSetRecoilState(svState);
  const { permalink } = useParams<{ permalink: string }>();
  const { user } = useUser();
  const [query, setQuery] = useQueryParams({
    scode: StringParam,
  });
  const [currMixdownId, setCurrMixdownId] = useState<string | null>(null);
  const [joinAttempted, setJoinAttempted] = useState(false);
  const {
    data,
    isValidating: loadingProject,
    mutate: mutateProject,
    error,
  } = useProjectData(permalink as string);
  const setProject = useSetRecoilState(projectState);
  const resetState = useResetRecoilState(svState);

  const { data: markups, mutate: mutateMarkups } = useMarkups(
    data?.project.id || "",
    {
      sortDir: "DESC",
    }
  );

  const value: ProjectContextType = {
    project: data?.project,
    loading: loadingProject,
    mutate: mutateProject,
    markups: markups?.data || [],
    mutateMarkups: mutateMarkups,
    view: data?.role || "viewer",
    currMixdownId,
    setCurrMixdownId,
  };

  // Set current mixdown when project is loaded
  useEffect(() => {
    if (data?.project?.mixdowns?.length) {
      setCurrMixdownId(data.project.mixdowns[0].id);
    }
  }, [data?.project?.mixdowns]);

  // Reset state when StemViewer is exited
  useDeepEffect(() => {
    resetState();
    setSvState((state) => ({
      ...state,
      markup: { ...state.markup, loading: true },
    }));

    (async () => {
      if (user) {
        // Fetch markups
        const { data: markups } = await fetchMarkups(data?.project);

        // Set state
        setSvState((state) => ({
          ...state,
          markup: {
            ...state.markup,
            loading: false,
            markups: markups?.data || [],
          },
          track: { ...state.track, project: data?.project },
          collab: { ...state.collab, me: user },
        }));
      }
    })().catch(console.error);

    if (data?.project) {
      setProject(data.project);
    }

    return resetState;
  }, [data?.project.id, user]);

  useEffect(() => {
    if (!joinAttempted && query.scode && error?.response?.status === 401) {
      joinSharedProject(query.scode).then(async ({ error }) => {
        setJoinAttempted(true);
        if (error) return notification.error(error.message);
        setQuery({ scode: undefined });
        await mutateProject();
      });
    }
  }, [query.scode, error]);

  return (
    <Context.Provider value={value}>
      <ProjectAuthWrapper error={error}>
        {error ? (
          errorNavigator(error)
        ) : (
          <Routes>
            <Route path=":tab?/*" element={<ProjectView />} />
            <Route
              path="viewer/markup?/:markupId?"
              element={
                isMobile ? (
                  <div className="h-[calc(100%-16rem)] flex flex-col justify-center items-center text-center p-6">
                    <h1 className="pb-4 text-8xl font-bold mb-4 bg-gradient-to-tr from-purple-400 to-blue-400 bg-clip-text text-transparent">
                      Sorry :(
                    </h1>
                    <p className="text-2xl">
                      We&apos;re still working on the mobile version of the Stem
                      Viewer. Please use a desktop or laptop for now.
                    </p>
                  </div>
                ) : isSafari ? (
                  <div className="h-[90vh] flex items-center justify-center p-10">
                    <div className="text-center">
                      <h1 className="text-5xl font-bold pb-4 bg-gradient-to-tr from-purple-400 to-blue-400 bg-clip-text text-transparent">
                        Sorry, Safari does not vibe with Synqup :(
                      </h1>
                      <p className="text-xl">
                        Please use Google Chrome, Firefox, or Microsoft Edge to
                        access this app.
                      </p>
                    </div>
                  </div>
                ) : (
                  <StemViewer />
                )
              }
            />
          </Routes>
        )}
      </ProjectAuthWrapper>
    </Context.Provider>
  );
};

type ProjectAuthWrapperProps = {
  error: SWRResponse["error"];
  children: React.ReactNode;
};

const ProjectAuthWrapper: React.FC<ProjectAuthWrapperProps> =
  withAuthConditional(
    ({ children }) => {
      return <>{children}</>;
    },
    ({ error }) => !!error
  );
