import { Card, RichText } from "@common/components";
import { isEmpty, SIMPLE_CONTROLS } from "@common/utils/editor";
import { UseAsyncFormReturnType } from "@common/utils/useAsyncForm";
import { useClickOutside } from "@mantine/hooks";
import { Description } from "@mui/icons-material";
import DOMPurify from "dompurify";
import React, { useEffect, useState } from "react";
import { useProject } from "../ProjectContext";
import { UpdateProjectDto } from "@server/modules/project/project/dto";
import { updateProjectPost } from "../../../../requests/project/project";
import { notification } from "@common/utils/notification";

export interface ProjectDescriptionProps {
  form: UseAsyncFormReturnType<UpdateProjectDto>;
}

const ProjectDescription: React.FC<ProjectDescriptionProps> = ({
  form,
  ...props
}) => {
  const { project, view } = useProject();

  const [editing, setEditing] = useState(!view);
  const [body, setBody] = useState(form.values.description);
  const ref = useClickOutside(() => {
    if (!!view) {
      handleSave();
    }
  });

  useEffect(() => {
    form.setFieldValue("description", body);
  }, [body]);

  const handleEdit = () => {
    if (view === "admin") {
      setBody(form.values.description);
      setEditing(true);
    }
  };

  const handleSave = async () => {
    if (!project?.id) return;
    if (!form.isDirty) return;

    const { error } = await form.sendForm(
      (values) => {
        return updateProjectPost(project.id, {
          description: values.description,
        });
      },
      {
        resetInitial: true,
      }
    );

    if (error) return notification.error(error.message);
    setEditing(false);
  };

  if (isEmpty(form.values?.description) && view && view !== "admin")
    return null;

  if (editing) {
    return (
      <div
        ref={ref}
        className="mt-4"
        data-testid="project-description"
        data-quick-assist-id="project-description"
        {...props}
      >
        <RichText
          controls={SIMPLE_CONTROLS}
          data-testid="project-description-input"
          value={body}
          onChange={setBody}
        />
      </div>
    );
  }

  if (!isEmpty(form.values?.description)) {
    return (
      <Card
        data-testid="project-description"
        data-quick-assist-id="project-description"
        className="h-full my-4"
        {...props}
      >
        <div
          className={
            view === "viewer"
              ? ""
              : "p-2 -m-2 rounded transition hover:bg-dark-1000"
          }
          onClick={handleEdit}
          data-testid="project-description-text"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(form.values?.description),
          }}
        />
      </Card>
    );
  }

  if (isEmpty(form.values?.description) && view === "admin") {
    return (
      <Card
        data-testid="project-description"
        data-quick-assist-id="project-description"
        className="h-full my-4"
        {...props}
      >
        <div
          className="text-center my-4 cursor-pointer transition ease-in-out hover:scale-105 hover:text-primary-400"
          onClick={() => setEditing(true)}
        >
          <Description fontSize="large" />
          <p>Add a description</p>
        </div>
      </Card>
    );
  }

  return null;
};

export { ProjectDescription };
