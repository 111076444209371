import { Badge, BadgeProps, ThemeIcon } from "@mantine/core";
import { Project } from "@server/entities";
import { Pause, PlayArrowRounded } from "@mui/icons-material";
import { CheckIcon } from "@modulz/radix-icons";

export const projectStatusData = [
  {
    value: "paused",
    label: "Paused",
    data: "paused",
    color: "yellow",
    icon: <Pause className="w-4 h-4" />,
  },
  {
    value: "ongoing",
    label: "Ongoing",
    data: "ongoing",
    color: "blue",
    icon: <PlayArrowRounded className="w-4 h-4" />,
  },
  {
    value: "completed",
    label: "Completed",
    data: "completed",
    color: "green",
    icon: <CheckIcon className="w-4 h-4" />,
  },
];

export interface ProjectStatusBadgeProps extends BadgeProps {
  status: Project["status"];
  icon?: boolean;
}

export const ProjectStatusBadge: React.FC<ProjectStatusBadgeProps> = ({
  status,
  icon,
  ...props
}) => {
  if (icon) {
    if (status === "ongoing") return null;
    return (
      <ThemeIcon
        className={props.className}
        size={props.size}
        radius={props.radius}
        variant="light"
        color={projectStatusData.find((d) => d.value === status)?.color}
      >
        {projectStatusData.find((d) => d.value === status)?.icon}
      </ThemeIcon>
    );
  }
  return (
    <Badge
      {...props}
      radius="sm"
      color={projectStatusData.find((d) => d.value === status)?.color}
    >
      {status}
    </Badge>
  );
};
