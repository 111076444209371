import React from "react";
import { Transition, Loader, Pagination, Table } from "@mantine/core";
import { Link } from "react-router-dom";
import { useProjects } from "../../requests/project/project";
import { withAuth } from "../routes/ProtectedRoute";
import { Button, Divider, Input, Menu } from "@common/components";
import { MagnifyingGlassIcon } from "@modulz/radix-icons";
import { NumberParam, StringParam, useQueryParams } from "use-query-params";
import { QueryProjectDto } from "@server/modules/project/project/dto";
import { ArrowUp, Check, Plus } from "tabler-icons-react";
import { Helmet } from "react-helmet";
import { useUser } from "../../contexts/UserContext";
import { ProjectRow } from "./components/ProjectRow";
import { SelectProjectStatus } from "./components/SelectProjectStatus";

export const Projects = withAuth(() => {
  const { starred } = useUser();

  const [query, setQuery] = useQueryParams({
    page: NumberParam,
    q: StringParam,
    filter: StringParam,
    sortBy: StringParam,
    dir: StringParam,
    status: StringParam,
  });

  const { data, isValidating: loading } = useProjects({
    page: query.page || 1,
    query: query.q || undefined,
    filter: query.filter || undefined,
    sortBy: (query.sortBy as QueryProjectDto["sortBy"]) || undefined,
    dir: (query.dir as QueryProjectDto["dir"]) || undefined,
    status: (query.status || "ongoing") as QueryProjectDto["status"],
  });

  const filterOptions = [
    { value: "all", label: "All projects" },
    { value: "mine", label: "My projects" },
    { value: "invited", label: "Invited projects" },
  ];

  const sortOptions = [
    { value: "name", label: "Project name" },
    { value: "createdAt", label: "Created at" },
    { value: "updatedAt", label: "Last opened" },
  ];

  return (
    <div className="relative container">
      <Helmet>
        <title>Projects | Synqup</title>
      </Helmet>

      <div className="w-full flex flex-col md:flex-row items-start md:items-center justify-between gap-4 mb-8">
        <h1 className="md:my-8">Projects</h1>

        <Link
          to="/projects/create"
          data-testid="create-project"
          data-quick-assist-id="create-project"
        >
          <Button leftIcon={<Plus className="w-5 h-5" />}>
            Create project
          </Button>
        </Link>
      </div>

      {starred.length > 0 && (
        <div className="mb-6">
          <h2>Favourites</h2>
          <Divider />

          <div className="rounded overflow-hidden bg-dark-800">
            <Table className="w-full">
              <tbody>
                {starred.map((project) => (
                  <ProjectRow
                    key={project.id}
                    data-testid="favourite-project-card"
                    project={project}
                  />
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      )}

      <h2>All projects</h2>
      <Divider />

      <div className="relative mb-4">
        <Input
          data-testid="search-projects"
          placeholder="Search projects"
          icon={<MagnifyingGlassIcon className="text-dark-500" />}
          value={query.q || ""}
          onChange={(e) =>
            setQuery((query) => ({ ...query, page: 1, q: e.target.value }))
          }
        />

        <div className="absolute right-2 top-0 flex items-center gap-2">
          <SelectProjectStatus
            variant="filled"
            size="xs"
            className="w-32"
            value={(query.status || "ongoing") as QueryProjectDto["status"]}
            onChange={(status) => setQuery((query) => ({ ...query, status }))}
          />

          <Menu>
            <Menu.Target>
              <Button
                data-testid="filter-projects"
                data-quick-assist-id="filter-project"
                size="xs"
                variant={query.filter ? "filled" : "light"}
                color={query.filter ? undefined : "gray"}
              >
                Filter
              </Button>
            </Menu.Target>

            <Menu.Dropdown>
              {filterOptions.map((option) => (
                <Menu.Item
                  data-testid={`filter-${option.value}`}
                  key={option.value}
                  icon={
                    query.filter === option.value ? (
                      <Check className="w-4 h-4" />
                    ) : (
                      <div className="w-4" />
                    )
                  }
                  sx={(theme) => ({
                    color:
                      query.filter === option.value
                        ? theme.colors.purple[3]
                        : undefined,
                  })}
                  onClick={(e) => {
                    e.stopPropagation();
                    setQuery((query) => ({
                      ...query,
                      page: 1,
                      filter: option.value,
                    }));
                  }}
                >
                  {option.label}
                </Menu.Item>
              ))}
            </Menu.Dropdown>
          </Menu>
          <Menu>
            <Menu.Target>
              <Button
                data-testid="sort-projects"
                data-quick-assist-id="sort-project"
                size="xs"
                variant={query.sortBy ? "filled" : "light"}
                color={query.sortBy ? undefined : "gray"}
              >
                Sort
              </Button>
            </Menu.Target>

            <Menu.Dropdown>
              {sortOptions.map((option) => (
                <Menu.Item
                  data-testid={`sort-${option.value}`}
                  key={option.value}
                  icon={
                    query.sortBy === option.value ? (
                      query.dir === "ASC" ? (
                        <ArrowUp className="w-4 h-4" />
                      ) : (
                        <ArrowUp className="w-4 h-4 rotate-180" />
                      )
                    ) : (
                      <div className="w-4" />
                    )
                  }
                  sx={(theme) => ({
                    color:
                      query.sortBy === option.value
                        ? theme.colors.purple[3]
                        : undefined,
                  })}
                  onClick={(e) => {
                    e.stopPropagation();
                    setQuery((query) => ({
                      ...query,
                      page: 1,
                      sortBy: option.value,
                      dir: query.dir === "ASC" ? "DESC" : "ASC",
                    }));
                  }}
                >
                  {option.label}
                </Menu.Item>
              ))}
            </Menu.Dropdown>
          </Menu>
        </div>
      </div>

      <Transition mounted={loading} transition="fade">
        {(styles) => (
          <Loader
            style={styles}
            className="absolute left-1/2 -translate-x-1/2 translate-y-8"
            size="sm"
          />
        )}
      </Transition>

      <div className="rounded overflow-hidden bg-dark-800">
        <Table className="w-full">
          <tbody>
            {data &&
              (data.data.length === 0 ? (
                <td className="text-center text-gray-500 col-span-3 py-8">
                  No projects found.
                </td>
              ) : (
                data.data.map((project) => (
                  <ProjectRow
                    key={project.id}
                    data-testid="project-card"
                    project={project}
                  />
                ))
              ))}
          </tbody>
        </Table>
      </div>

      <div className="flex justify-center">
        <Pagination
          className="mt-4"
          size="sm"
          page={query.page || 1}
          onChange={(page) => setQuery((query) => ({ ...query, page }))}
          total={data?.pages || 1}
        />
      </div>
    </div>
  );
});
