import React, { useEffect, useMemo, useRef, useState } from "react";
import { UseAsyncFormReturnType } from "@common/utils/useAsyncForm";
import { UpdateProjectDto } from "@server/modules/project/project/dto";
import {
  useDebouncedValue,
  useElementSize,
  useViewportSize,
} from "@mantine/hooks";
import { useWorker } from "@common/utils/use-worker";
import { stemHeight } from "../../../stemviewer/helpers/constants";
import { Card, Loader, Portal, useMantineTheme } from "@mantine/core";
import { useDropzone } from "react-dropzone";
import { ActionIcon, Button, Select, Tooltip } from "@common/components";
import { getBuffer } from "../../../stemviewer/recoil/helpers/stem";
import { usePlayer, usePlayerState } from "../../../../contexts/Player";
import { MixdownPanel } from "./components/MixdownPanel";
import { useDrag } from "@use-gesture/react";
import { useDeepEffect } from "@common/utils/use-deep-effect";
import axios from "axios";
import Skeleton from "@common/components/Skeleton";
import { useProject } from "../ProjectContext";
import {
  MixdownCompare,
  MixdownCompareVersions,
} from "./components/mixdown-compare/MixdownCompareVersions";
import { Howl } from "howler";
import { MixdownMarkup } from "./components/mixdown-markup/MixdownMarkup";
import { FaPenNib } from "react-icons/fa";
import { CreateMarkup } from "./components/mixdown-markup/CreateMarkup";
import { pixelsToTime, timeToPixels } from "@common/utils/time-pixel-converter";
import { ChevronLeft } from "tabler-icons-react";
import { draw } from "@common/components/PlayableAudioWaveform";
import { updateProject } from "../../../../requests/project/project";
import { notification } from "@common/utils/notification";
import { MixdownDescription } from "./components/MixdownDescription";

interface ProjectMixdownProps {
  form: UseAsyncFormReturnType<UpdateProjectDto>;
}

const createWorker = () =>
  new Worker(
    new URL(
      "../../../stemviewer/helpers/workers/get-audio-array-buffer",
      import.meta.url
    ),
    { type: "module" }
  );

export const ProjectMixdown: React.FC<ProjectMixdownProps> = ({ form }) => {
  const {
    project,
    currMixdownId: projectCurrMixdownId,
    setCurrMixdownId: setProjectCurrMixdownId,
    markups,
    view,
    mutate,
  } = useProject();
  // Since create project page is not using project context
  const [_currMixdownId, _setCurrMixdownId] = useState<string | null>(null);
  const currMixdownId =
    projectCurrMixdownId === undefined ? _currMixdownId : projectCurrMixdownId;
  const setCurrMixdownId =
    projectCurrMixdownId === undefined
      ? _setCurrMixdownId
      : setProjectCurrMixdownId;
  const { seek, setVisible, unload } = usePlayer();
  const {
    currTime: _currTime,
    duration,
    audio,
  } = usePlayerState(["currTime", "duration", "audio"]);
  const currTime = audio?.projectId === form.values.id ? _currTime : 0;
  const theme = useMantineTheme();
  const [loading, setLoading] = useState(false);
  const [buffer, setBuffer] = useState<AudioBuffer | null>(null);
  const [arrayBuffer, setArrayBuffer] = useState<number[]>([]);
  const [canvas, setCanvas] = useState<HTMLCanvasElement | null>(null);
  const { ref, width } = useElementSize();
  const [debouncedWidth] = useDebouncedValue(width - 16, 200);
  const [comparingMixdowns, setComparingMixdowns] = useState(false);
  const [compareMixdowns, setCompareMixdowns] =
    useState<Array<MixdownCompare> | null>(null);
  const [mixdownsLoaded, setMixdownsLoaded] = useState(0);
  const compareMixdownAbortController = useRef<AbortController | null>(null);
  const [region, setRegion] = useState<[number, number] | null>(null);
  const [createMarkup, setCreateMarkup] = useState(false);
  const [createMarkupHovered, setCreateMarkupHovered] = useState(false);
  const { width: vpWidth } = useViewportSize();
  const isMobile = vpWidth < theme.breakpoints.md;

  const { isDragActive, getRootProps, getInputProps, inputRef } = useDropzone({
    accept: "audio/*",
    multiple: false,
    noClick: true,
    onDrop: async (files) => {
      setLoading(true);
      const file = files[0];
      unload();
      setCurrMixdownId(null);

      const reader = new FileReader();

      reader.onload = async (e) => {
        const buffer = await getBuffer(e.target?.result as ArrayBuffer);

        form.setValues(
          {
            ...form.values,
            file,
            mixdown: {
              duration: buffer.duration,
            },
          },
          true
        );

        if (project?.id) {
          const { error } = await updateProject(project.id, {
            file: file,
            mixdown: {
              duration: buffer.duration,
            },
          });

          if (error) {
            notification.error(error.message);
          } else {
            await mutate();
          }
        }

        setLoading(false);
        setBuffer(buffer);
      };

      reader.onerror = console.error;

      reader.readAsArrayBuffer(file);
    },
  });

  const currMixdown = project?.mixdowns?.find(
    (mixdown) => mixdown.id === currMixdownId
  );

  const scale = useMemo(
    () =>
      buffer && debouncedWidth !== 16 ? 4 / (debouncedWidth - 16) : undefined,
    [buffer, debouncedWidth]
  );

  const [{ result }] = useWorker<{ array: number[] }>(createWorker, {
    buffer: buffer?.getChannelData(0),
    duration: buffer?.duration,
    scale,
  });

  // Set mixdown when project loaded
  useEffect(() => {
    if (currMixdownId) return;

    if (project?.mixdowns && project.mixdowns.length > 0) {
      setCurrMixdownId(project.mixdowns[0].id);
    }
  }, [project?.mixdowns]);

  // Load mixdown from file if it exists
  useDeepEffect(() => {
    if (!currMixdown?.file?.url) return;

    setLoading(true);

    axios
      .get(currMixdown.file.url, {
        responseType: "blob",
      })
      .then((response) => {
        return new File([response.data], form.values.name, {
          type: "audio/mp3",
        }).arrayBuffer();
      })
      .then((arrayBuffer) => getBuffer(arrayBuffer))
      .then((buffer) => {
        setBuffer(buffer);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [currMixdown]);

  // Set array buffer whenever scale changed
  useEffect(() => {
    if (!result) return;
    if (!Array.isArray(result.array)) return;
    if (!buffer || !canvas) return;
    if (result?.array.length > 0) {
      setArrayBuffer(result.array);
      draw(canvas, result.array);
    }
  }, [result]);

  // Draw stem whenever array buffer or track duration changes
  useEffect(() => {
    if (!buffer || !canvas) return;
    draw(canvas, arrayBuffer);
  }, [canvas, buffer]);

  // Play audio whenever user drags the needle
  const bind = useDrag(({ xy, down, initial, movement, event }) => {
    const isPlaying = audio?.projectId === form.values.id;

    if (!buffer || !down) return;

    const mixdownCanvas = document.getElementById("mixdown_canvas");
    const mixdownCanvasRect = mixdownCanvas?.getBoundingClientRect();
    if (!mixdownCanvasRect) return;

    const time = pixelsToTime(
      xy[0] - mixdownCanvasRect.left,
      debouncedWidth,
      buffer.duration
    );

    // Hide create markup when dragging
    setCreateMarkup(false);

    // Selecting region
    if (!isMobile && (movement[0] > 15 || movement[0] < -15)) {
      const startTime = pixelsToTime(
        initial[0] - mixdownCanvasRect.left,
        debouncedWidth,
        buffer.duration
      );

      if (time < startTime) {
        setRegion([time, startTime]);
      } else {
        setRegion([startTime, time]);
      }

      if (isPlaying) seek(startTime);
      return;
    }

    // Unsetting region
    if (region && (time < region[0] || time > region[1])) {
      setRegion(null);
    }

    if (isPlaying) seek(time);
  }, {});

  // Hide player if playing the mixdown
  useEffect(() => {
    if (audio?.projectId === form.values.id) {
      setVisible(false);
    }

    return () => {
      if (audio) setVisible(true);
    };
  }, [audio]);

  const handleOpen = () => {
    inputRef.current?.click();
  };

  const handleMixdownChange = async (id: string) => {
    if (id === currMixdownId) return;
    unload();
    setCurrMixdownId(id);
  };

  const handleMixdownCompare = async (compareMixdownId: string) => {
    const compareMixdown = project?.mixdowns.find(
      (mixdown) => mixdown.id === compareMixdownId
    );

    if (compareMixdownId === currMixdownId) return;
    if (!compareMixdown?.file?.url) return;
    if (!currMixdown?.file?.url) return;
    if (!buffer) return;

    setComparingMixdowns(true);
    compareMixdownAbortController.current = new AbortController();

    const compareBuffer = await axios
      .get(compareMixdown?.file?.url, {
        responseType: "blob",
        signal: compareMixdownAbortController.current?.signal,
      })
      .then((response) => {
        return new File([response.data], form.values.name, {
          type: "audio/mp3",
        }).arrayBuffer();
      })
      .then((arrayBuffer) => getBuffer(arrayBuffer));

    compareMixdownAbortController.current = null;

    unload();
    setCompareMixdowns([
      {
        ...compareMixdown,
        howl: new Howl({
          src: compareMixdown.file.url,
          format: ["webm", "mp3"],
          volume: 0,
          onload: () => setMixdownsLoaded((loaded) => loaded + 1),
          onloaderror: console.log,
        }),
        buffer: compareBuffer,
        selected: false,
      },
      {
        ...currMixdown,
        howl: new Howl({
          src: currMixdown.file.url,
          format: ["webm", "mp3"],
          volume: 1,
          onload: () => setMixdownsLoaded((loaded) => loaded + 1),
          onloaderror: console.log,
        }),
        buffer,
        selected: true,
      },
    ]);
  };

  const cancelCompareMixdown = () => {
    compareMixdownAbortController.current?.abort();
    setMixdownsLoaded(0);
    setComparingMixdowns(false);
    setCompareMixdowns(null);
  };

  const closeCompareMixdown = () => {
    setMixdownsLoaded(0);
    setComparingMixdowns(false);
    setCompareMixdowns(null);
  };

  if (loading) return <Skeleton visible height={149} />;

  // No mixdowns uploaded yet
  if (!buffer)
    if (view !== "viewer")
      return (
        <Card
          data-quick-assist-id="project-mixdown"
          ref={ref}
          style={{
            border: isDragActive
              ? `2px dashed ${theme.colors.indigo[5]}`
              : `2px solid ${theme.colors.dark[6]}`,
          }}
          className="mb-6 p-1 shadow-2xl"
          {...getRootProps()}
        >
          <div
            className="flex justify-center items-center gap-4"
            style={{
              margin: 8,
              height: stemHeight,
            }}
          >
            <p className="text-dark-400 m-0">Drag and drop your demo here</p>
            <Button size="xs" variant="light" onClick={handleOpen}>
              Upload
            </Button>
            <input
              data-testid="project-mixdown-upload-input"
              {...getInputProps()}
            />
          </div>
        </Card>
      );
    else return null;

  // Comparing mixdowns
  if (comparingMixdowns)
    return (
      <div>
        {compareMixdowns && mixdownsLoaded === 2 ? (
          <MixdownCompareVersions
            mixdowns={compareMixdowns}
            onClose={closeCompareMixdown}
          />
        ) : (
          <div className="relative flex items-center justify-center gap-4 h-40">
            <Button
              leftIcon={<ChevronLeft className="w-3 h-3" />}
              variant="light"
              color="gray"
              size="xs"
              className="absolute right-2 top-2 z-30"
              onClick={cancelCompareMixdown}
            >
              Back
            </Button>

            <Loader size="sm" />
            <p className="text-center text-dark-400">Loading mixdowns...</p>
          </div>
        )}
      </div>
    );

  return (
    <div>
      {project?.mixdowns && project.mixdowns.length > 0 && (
        <Portal target={document.getElementById("project-hotbar")!}>
          <Select
            data-quick-assist-id="mixdown-select"
            className="w-32 md:w-48"
            classNames={{ input: "my-0" }}
            size="sm"
            data={
              project.mixdowns?.map((mixdown) => ({
                label: mixdown.name,
                value: mixdown.id,
              })) || []
            }
            value={currMixdown?.id}
            onChange={(value) => {
              unload();
              if (value) handleMixdownChange(value);
            }}
          />
        </Portal>
      )}

      <Card
        className="p-1 shadow-2xl"
        style={{
          background: `linear-gradient(90deg, ${theme.colors.indigo[7]}, ${theme.colors.rose[7]})`,
        }}
        data-testid="project-mixdown"
        data-quick-assist-id="project-mixdown"
        {...bind()}
      >
        <div {...getRootProps()} onFocus={(e) => e.target.blur()}>
          <div
            style={{
              position: "relative",
              touchAction: "none",
            }}
            ref={ref}
          >
            {!isMobile && region && (
              <div
                className="absolute -top-2 h-[110%] bg-blue-400 opacity-80 mix-blend-color-dodge rounded"
                style={{
                  left:
                    timeToPixels(region[0], debouncedWidth, buffer.duration) +
                    9,
                  width: timeToPixels(
                    region[1] - region[0],
                    debouncedWidth,
                    buffer.duration
                  ),
                }}
              />
            )}

            <div
              className="absolute z-50 top-1/2 -translate-y-1/2"
              style={{
                left:
                  timeToPixels(currTime, debouncedWidth, buffer.duration) + 16,
              }}
            >
              {isMobile || view === "viewer" ? null : createMarkup ? (
                <CreateMarkup
                  start={region ? region[0] : currTime}
                  end={region ? region[1] : undefined}
                  onClose={() => setCreateMarkup(false)}
                />
              ) : (
                <Tooltip label="Add markup">
                  <ActionIcon
                    data-testid="sv-create-markup"
                    data-quick-assist-id="sv-create-markup"
                    variant="filled"
                    color="dark"
                    style={{
                      opacity: createMarkupHovered ? 1 : 0.5,
                    }}
                    onClick={() => setCreateMarkup(true)}
                    onPointerDown={(e) => e.stopPropagation()}
                    onMouseOver={() => setCreateMarkupHovered(true)}
                    onMouseLeave={() => setCreateMarkupHovered(false)}
                  >
                    <FaPenNib className="w-4 h-4 text-red-500" />
                  </ActionIcon>
                </Tooltip>
              )}
            </div>

            <div
              className="absolute px-4 -top-2 -left-4 h-[110%] bg-indigo-700 opacity-80 mix-blend-color-dodge rounded"
              style={{
                width: timeToPixels(currTime, debouncedWidth, duration) + 22,
                transition: "width 200ms linear",
              }}
            />

            {markups
              ?.filter(
                (markup) =>
                  markup.mixdownId &&
                  currMixdownId &&
                  markup.mixdownId === currMixdownId
              )
              ?.map((markup) => (
                <MixdownMarkup
                  key={markup.id}
                  markup={markup}
                  duration={buffer.duration}
                  width={debouncedWidth}
                />
              ))}

            <canvas
              id="mixdown_canvas"
              className="animate-opacity max-w-full"
              ref={setCanvas}
              style={{
                margin: 8,
                height: stemHeight,
              }}
            />

            <input
              data-testid="project-mixdown-upload-input"
              {...getInputProps()}
            />
          </div>
        </div>
      </Card>

      {buffer && (
        <MixdownPanel
          buffer={buffer}
          currMixdown={currMixdown}
          project={form.values}
          file={form.values.file}
          onUpload={handleOpen}
          onMixdownChange={handleMixdownChange}
          onMixdownCompare={handleMixdownCompare}
        />
      )}

      <div className="-mt-2">
        {currMixdown && <MixdownDescription mixdown={currMixdown} />}
      </div>
    </div>
  );
};
