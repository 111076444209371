import React from "react";
import { Search, SearchProps } from "@common/components";
import { useProjects } from "../../../requests/project/project";
import { Project } from "@server/entities";
import { Avatar } from "@mantine/core";

interface SearchProjectsProps
  extends Omit<SearchProps<any>, "value" | "onChange" | "data"> {
  value: Project | undefined;
  onChange: (value: Project | null) => void;
}

const SearchProjects: React.FC<SearchProjectsProps> = ({
  value,
  onChange,
  ...props
}) => {
  const { data: projects } = useProjects();

  return (
    <Search<Project>
      data={
        projects?.data.map((project) => ({
          value: project.id,
          label: project.name,
          data: project,
        })) || []
      }
      value={value?.id}
      onChange={(id) => {
        const project = projects?.data.find((project) => project.id === id);
        onChange(project || null);
      }}
      renderOption={(option) => (
        <div key={option.value} className="flex items-center gap-2 m-2">
          <Avatar
            size="sm"
            src={option.data?.albumArt}
            alt={option.data.name}
          />
          <span className="text-sm line-clamp-2">{option.data.name}</span>
        </div>
      )}
      placeholder="Search project"
      {...props}
    />
  );
};

export default SearchProjects;
