import React, { Fragment, useEffect, useState } from "react";
import { ActionIcon, Button, Menu, Tooltip } from "@common/components";
import { useMyMarkups } from "../../../requests/project/markup";
import { ArrowsSort, ArrowUp, ChevronDown } from "tabler-icons-react";
import { uniqBy } from "lodash";
import { TaskBoard, TaskBoardState } from "../components/TaskBoard";
import { Markup, Project } from "@server/entities";
import { useTasks } from "../../../contexts/Tasks/TaskContext";
import { AnimatePresence, motion } from "framer-motion";
import { useLocalStorage } from "@mantine/hooks";
import { SearchProjects } from "../../projects/components/SearchProjects";
import { BsArrowsCollapse, BsArrowsExpand } from "react-icons/bs";
import { withAuth } from "../../routes/ProtectedRoute";
import { TasksTable } from "../components/TasksTable";

export const Tasks: React.FC = withAuth(() => {
  const [assignedByMe, setAssignedByMe] = useLocalStorage({
    key: "tasks.assignedByMe",
    defaultValue: false,
  });
  const [assignedToMe, setAssignedToMe] = useLocalStorage({
    key: "tasks.assignedToMe",
    defaultValue: false,
  });
  const [project, setProject] = useState<Project | null>(null);
  const [sortBy, setSortBy] = useState("project.updatedAt");
  const [sortDir, setSortDir] = useState<"ASC" | "DESC">("DESC");

  const { data, mutate } = useMyMarkups({
    projectId: project?.id,
    type: ["task", "markup"],
    withApproved: true,
    limit: 100,
    sortBy: sortBy as keyof Markup,
    sortDir,
    assignedByMe: assignedByMe ? true : undefined,
    assignedToMe: assignedToMe ? true : undefined,
  });

  const { mutateTasks } = useTasks();

  const [state, setState] = useState<Array<{
    project: Project;
    state: TaskBoardState;
    collapsed: boolean;
  }> | null>(null);

  // Mutate on task create
  useEffect(() => {
    mutate();
  }, [mutateTasks]);

  useEffect(() => {
    const projects = uniqBy(
      data?.data.map((markup) => markup.project!),
      "id"
    );

    setState(
      projects.map((project) => ({
        collapsed: false,
        project,
        state: {
          columns: [
            {
              status: "pending",
              color: "blue",
              label: "To do",
              markups:
                data?.data.filter(
                  (markup) =>
                    markup.project?.id === project.id &&
                    markup.status === "pending"
                ) || [],
            },
            {
              status: "needs_approval",
              color: "yellow",
              label: "In progress",
              markups:
                data?.data.filter(
                  (markup) =>
                    markup.project?.id === project.id &&
                    markup.status === "needs_approval"
                ) || [],
            },
            {
              status: "approved",
              color: "green",
              label: "Done",
              markups:
                data?.data.filter(
                  (markup) =>
                    markup.project?.id === project.id &&
                    markup.status === "approved"
                ) || [],
            },
          ],
        },
      }))
    );
  }, [data]);

  const allCollapsed = state?.every((item) => item.collapsed);

  const handleCollapseAll = () => {
    setState(
      (prev) =>
        prev?.map((item) => ({ ...item, collapsed: !allCollapsed })) || null
    );
  };

  const handleCollapse = (projectId: string) => {
    setState(
      (prev) =>
        prev?.map((item) =>
          item.project.id === projectId
            ? { ...item, collapsed: !item.collapsed }
            : item
        ) || null
    );
  };

  const handleSort = (value: string) => {
    if (sortBy === value) {
      setSortDir((prev) => (prev === "ASC" ? "DESC" : "ASC"));
    } else {
      setSortBy(value);
      setSortDir("DESC");
    }
  };

  return (
    <div className="container">
      <div className="flex justify-between items-end mb-4 gap-4">
        <SearchProjects
          label="Search projects"
          className="flex-1"
          value={project}
          onChange={setProject}
        />
      </div>

      <div className="flex justify-between">
        <div className="flex gap-2">
          <Button
            data-testid="task-filter-assigned-to-me"
            variant={assignedToMe ? "filled" : "light"}
            color={assignedToMe ? "primary" : "gray"}
            onClick={() => setAssignedToMe((prev) => !prev)}
          >
            Assigned to me
          </Button>

          <Button
            data-testid="task-filter-assigned-by-me"
            variant={assignedByMe ? "filled" : "light"}
            color={assignedByMe ? "primary" : "gray"}
            onClick={() => setAssignedByMe((prev) => !prev)}
          >
            Assigned by me
          </Button>
        </div>

        <div className="flex gap-2">
          <Menu withinPortal position="bottom-end">
            <Menu.Target>
              <Tooltip label="Sort">
                <ActionIcon variant="light">
                  <ArrowsSort className="w-5 h-5" />
                </ActionIcon>
              </Tooltip>
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Item
                rightSection={
                  <ArrowUp
                    className="w-4 h-4 ml-1 -mb-0.5"
                    style={{
                      display:
                        sortBy === "project.updatedAt" ? "block" : "none",
                      transform: sortDir === "DESC" ? "rotate(180deg)" : "",
                    }}
                  />
                }
                sx={(theme) => ({
                  color:
                    sortBy === "project.updatedAt"
                      ? theme.colors.purple[3]
                      : undefined,
                })}
                onClick={() => handleSort("project.updatedAt")}
              >
                Sort by last opened
              </Menu.Item>

              <Menu.Item
                rightSection={
                  <ArrowUp
                    className="w-4 h-4 ml-1 -mb-0.5"
                    style={{
                      display: sortBy === "project.name" ? "block" : "none",
                      transform: sortDir === "DESC" ? "rotate(180deg)" : "",
                    }}
                  />
                }
                sx={(theme) => ({
                  color:
                    sortBy === "project.name"
                      ? theme.colors.purple[3]
                      : undefined,
                })}
                onClick={() => handleSort("project.name")}
              >
                Sort by name
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>

          <Tooltip label={allCollapsed ? "Expand all" : "Collapse all"}>
            <ActionIcon variant="light" onClick={handleCollapseAll}>
              {allCollapsed ? (
                <BsArrowsExpand className="w-5 h-5" />
              ) : (
                <BsArrowsCollapse className="w-5 h-5" />
              )}
            </ActionIcon>
          </Tooltip>
        </div>
      </div>

      <div className="my-10">
        {data?.count === 0 && (
          <div className="text-center text-gray-500">
            No tasks found. Create a task to get started.
          </div>
        )}

        {state?.map(({ project, state, collapsed }) => (
          <Fragment key={project.id}>
            <div className="bg-dark-800 border-b-primary-600 border-solid border-0 border-b-2 rounded px-3 py-2 my-4 flex justify-between items-center gap-2">
              <h3 className="text-xs uppercase text-dark-100">
                {project.name}
              </h3>

              <ActionIcon
                size="sm"
                color="white"
                variant="subtle"
                onClick={() => handleCollapse(project.id)}
              >
                <ChevronDown
                  className="w-5 h-5"
                  style={{
                    transform: collapsed ? "rotate(90deg)" : "rotate(0deg)",
                  }}
                />
              </ActionIcon>
            </div>

            <AnimatePresence>
              {!collapsed && (
                <motion.div
                  initial="collapsed"
                  animate="open"
                  exit="collapsed"
                  variants={{
                    open: { opacity: 1, width: "100%", height: "auto" },
                    collapsed: { opacity: 0, width: "100%", height: 0 },
                  }}
                  transition={{ type: "tween", duration: 0.15 }}
                >
                  <TaskBoard state={state} />
                </motion.div>
              )}
            </AnimatePresence>
          </Fragment>
        ))}
      </div>

      <TasksTable tasks={data?.data} mutate={mutate} />
    </div>
  );
});
