import React, { useEffect } from "react";
import { useMyMarkups } from "../../../../requests/project/markup";
import { TasksTable } from "../../../tasks/components/TasksTable";
import { useLocalStorage } from "@mantine/hooks";
import { Button, Card } from "@common/components";
import { useTasks } from "../../../../contexts/Tasks/TaskContext";

interface ProjectTasksProps {
  projectId: string;
}

export const ProjectTasks: React.FC<ProjectTasksProps> = ({ projectId }) => {
  const [assignedByMe, setAssignedByMe] = useLocalStorage({
    key: "tasks.assignedByMe",
    defaultValue: false,
  });
  const [assignedToMe, setAssignedToMe] = useLocalStorage({
    key: "tasks.assignedToMe",
    defaultValue: false,
  });

  const { createTask, mutateTasks } = useTasks();

  const { data, mutate } = useMyMarkups({
    projectId,
    type: ["task", "markup"],
    limit: 100,
    assignedByMe: assignedByMe ? true : undefined,
    assignedToMe: assignedToMe ? true : undefined,
  });

  // Mutate on task create
  useEffect(() => {
    mutate();
  }, [mutateTasks]);

  return (
    <Card className="bg-dark-800 p-6">
      <div className="flex justify-between mb-4">
        <div className="flex gap-2">
          <Button
            data-testid="task-filter-assigned-to-me"
            variant={assignedToMe ? "filled" : "light"}
            color={assignedToMe ? "primary" : "gray"}
            onClick={() => setAssignedToMe((prev) => !prev)}
          >
            Assigned to me
          </Button>

          <Button
            data-testid="task-filter-assigned-by-me"
            variant={assignedByMe ? "filled" : "light"}
            color={assignedByMe ? "primary" : "gray"}
            onClick={() => setAssignedByMe((prev) => !prev)}
          >
            Assigned by me
          </Button>
        </div>
      </div>

      <TasksTable mutate={mutate} tasks={data?.data} />
    </Card>
  );
};
