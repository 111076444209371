import React from "react";
import { UploadImageModal } from "@common/components/UploadImageModal";
import { Image } from "@mantine/core";
import { UseAsyncFormReturnType } from "@common/utils/useAsyncForm";
import { UpdateProjectDto } from "@server/modules/project/project/dto";
import { AvatarsGroup, Textarea } from "@common/components";
import { useProject } from "../ProjectContext";
import { getOverallColor } from "@common/utils/get-overall-color";
import { ProjectSocialIcons } from "./ProjectSocialIcons";
import Skeleton from "@common/components/Skeleton";
import { updateProjectPost } from "../../../../requests/project/project";
import { notification } from "@common/utils/notification";
import { useDeepEffect } from "@common/utils/use-deep-effect";
import { ProjectDescription } from "./ProjectDescription";

interface ProjectHeaderProps {
  form: UseAsyncFormReturnType<UpdateProjectDto>;
  onAddSocials?: () => void;
}

export const ProjectHeader: React.FC<ProjectHeaderProps> = ({
  form,
  onAddSocials,
}) => {
  const { project, view, loading } = useProject();

  const handleUpload = async (url) => {
    form.setFieldValue("albumArt", url);
    getOverallColor(url).then((color) => {
      form.setFieldValue("albumArtColor", color);
    });
  };

  useDeepEffect(() => {
    if (!form.values.albumArtColor) return;
    handleSave();
  }, [form.values.albumArtColor]);

  const handleSave = async () => {
    const { error } = await form.sendForm(
      (values) => {
        return updateProjectPost(project?.id as string, values);
      },
      {
        resetInitial: true,
      }
    );

    if (error) return notification.error(error.message);
  };

  return (
    <div className="w-full lg:w-[16rem] xl:w-[24rem]">
      <div className="flex-1 flex flex-row lg:flex-col items-center lg:items-start mb-6 gap-8 w-full">
        {loading ? (
          <Skeleton
            className="w-[10rem] md:w-[16rem] lg:w-full h-full aspect-square"
            visible
          />
        ) : view !== "viewer" ? (
          <UploadImageModal
            targetWidth={1280}
            targetHeight={1280}
            onUpload={handleUpload}
            projectId={project?.id}
            dataTestId="project-album-art"
          >
            <Image
              className="w-[10rem] md:w-[16rem] lg:w-full h-full aspect-square"
              radius="md"
              src={form.values.albumArt || "/images/no-album-art.png"}
              withPlaceholder
            />
          </UploadImageModal>
        ) : (
          <Image
            data-testid="project-album-art"
            className="w-[10rem] md:w-[16rem] lg:w-full h-auto"
            radius="md"
            src={form.values.albumArt || "/images/no-album-art.png"}
            withPlaceholder
          />
        )}

        <div className="flex-1">
          {loading ? (
            <Skeleton className="w-full h-16 mb-8" visible />
          ) : (
            <Textarea
              data-testid="project-title"
              data-quick-assist-id="project-title"
              {...form.getInputProps("name")}
              classNames={{
                root: "flex-1",
                input: `w-full font-bold text-5xl pl-2 -mx-2 bg-dark-1000 bg-opacity-0 ${
                  view !== "viewer"
                    ? "transition hover:bg-opacity-100"
                    : "border-none"
                }`,
              }}
              styles={{
                input: { border: "1px solid transparent" },
              }}
              placeholder="Untitled"
              autosize
              minRows={1}
              readOnly={view === "viewer"}
              onBlur={handleSave}
            />
          )}

          <div className="flex items-center justify-between gap-4 mt-2">
            <div className="flex items-center gap-4">
              {project && (
                <AvatarsGroup
                  noAnimation
                  size="sm"
                  users={project.collaborators.map((collab) => collab?.user)}
                />
              )}

              {project?.artist && (
                <p className="text-lg text-indigo-500 z-50">{project.artist}</p>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="flex items-center gap-2">
        {project && (
          <ProjectSocialIcons form={form} onAddSocials={onAddSocials} />
        )}
      </div>

      <div className="mt-4">
        <ProjectDescription form={form} />
      </div>
    </div>
  );
};
