import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Avatar, Tooltip } from "@mantine/core";
import dayjs from "dayjs";
import Checkbox from "@common/components/Checkbox";
import { DatePicker } from "@common/components";
import { notification } from "@common/utils/notification";
import { updateMarkup } from "../../../requests/project/markup";
import { Markup } from "@server/entities";

interface TaskRowProps {
  markup: Markup;
  onUpdate: () => void;
}

const TaskRow: React.FC<TaskRowProps> = ({ markup, onUpdate }) => {
  const navigate = useNavigate();
  const [dueDate, setDueDate] = useState<Date | null>(
    markup.dueDate ? new Date(markup.dueDate) : null
  );
  const [checked, setChecked] = useState(false);

  const handleCheck = async () => {
    if (!markup) return;
    setChecked((prev) => !prev);
    const { error } = await updateMarkup(markup.id, {
      status: checked ? "pending" : "approved",
    });
    if (error) return notification.error(error.message);
    onUpdate();
  };

  const handleOpen = () => {
    if (!markup) return;
    navigate(`?markupId=${markup.id}`);
  };

  const handleDateChange = async (date: Date | null) => {
    if (!markup) return;
    setDueDate(date);
    const { error } = await updateMarkup(markup.id, {
      dueDate: dayjs(date).toDate(),
    });
    if (error) return notification.error(error.message);
    onUpdate();
  };

  const isOverdue = dueDate && dayjs(dueDate).isBefore(dayjs(), "day");

  if (!markup) return null;

  return (
    <tr
      data-testid="task-row"
      className="cursor-pointer hover:bg-dark-900 transition"
    >
      <td>
        <Checkbox
          styles={{ label: { padding: 0 } }}
          checked={checked}
          size="sm"
          onClick={handleCheck}
        />
      </td>
      <td onClick={handleOpen}>
        <p
          style={{
            opacity: checked ? 0.5 : 1,
            textDecoration: checked ? "line-through" : "none",
          }}
          className="text-sm text-dark-200 p-0"
        >
          {markup.title}
        </p>
      </td>
      <td onClick={handleOpen}>
        <Tooltip label={markup.project?.name}>
          <Avatar src={markup.project?.albumArt} size="sm" />
        </Tooltip>
      </td>
      <td style={{ padding: 0 }}>
        <DatePicker
          name="dueDate"
          size="sm"
          inputFormat="D/M/YY"
          value={dueDate}
          onChange={handleDateChange}
          preventFocus
          classNames={{
            input: `w-24 border-0 ${
              isOverdue
                ? "bg-red-500 bg-opacity-20 hover:bg-opacity-40"
                : "bg-transparent hover:bg-dark-700"
            } px-3`,
            rightSection: "opacity-0 hover:opacity-100",
          }}
        />
      </td>
    </tr>
  );
};

export default TaskRow;
