import useAsyncForm from "@common/utils/useAsyncForm";
import { CreateProjectDto } from "@server/modules/project/project/dto";
import React, { useState } from "react";
import { v4 } from "uuid";
import * as Yup from "yup";
import { postProject } from "../../../../requests/project/project";
import { ProjectMixdown } from "../../view/home/ProjectMixdown";
import { FormActionButtons } from "@common/components/FormActionButtons";
import { ProjectCover } from "../../view/home/ProjectCover";
import { useUser } from "../../../../contexts/UserContext";
import { usePrompt } from "@common/utils/use-prompt";
import {
  ActionIcon,
  Button,
  Input,
  Modal,
  Textarea,
  Tooltip,
} from "@common/components";
import { useNavigate } from "react-router-dom";
import { ChevronRight, Clipboard } from "tabler-icons-react";
import { useClipboard } from "@mantine/hooks";
import { Alert, Image } from "@mantine/core";
import { Project } from "@server/entities";
import { Warning } from "@mui/icons-material";
import { LOGIN_URL } from "@common/utils/constants";
import { Helmet } from "react-helmet";
import { UploadImageModal } from "@common/components/UploadImageModal";
import { getOverallColor } from "@common/utils/get-overall-color";

const ProjectCreate: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useUser();
  const clipboard = useClipboard({ timeout: 500 });
  const [permalink] = useState(v4().split("-").join("").slice(0, 10));
  const [project, setProject] = useState<Project | null>(null);
  const [copied, setCopied] = useState(false);

  const form = useAsyncForm<CreateProjectDto>({
    initialValues: {
      name: undefined,
      permalink,
      collaborators: [],
      privacy: "public",
      artist: user?.pseudonym || user?.displayName || "Unknown",
    } as CreateProjectDto,
    schema: Yup.object().shape({
      name: Yup.string()
        .required("Project name is required")
        .trim()
        .min(1, "Project name has to be longer than 1 character"),
      collaborators: Yup.array(),
      privacy: Yup.string().matches(/(public|scheduled|private)/),
    }),
  });

  usePrompt(
    "You have not saved your changes. Are you sure you want to leave?",
    form.isDirty()
  );

  const handleSubmit = async () => {
    const { data, error } = await form.sendForm(
      (values) =>
        postProject({
          ...values,
          filetype: "mixdown",
          filekey: `public/umix/${v4()}`,
          versioning: true,
        }),
      {
        resetInitial: false,
      }
    );

    if (error || !data) return;

    setProject(data);
  };

  const handleUpload = async (url) => {
    form.setFieldValue("albumArt", url);
    getOverallColor(url).then((color) => {
      form.setFieldValue("albumArtColor", color);
    });
  };

  const handlePermalink = (e: React.ChangeEvent<HTMLInputElement>) => {
    form.setFieldValue("permalink", e.target.value.replace(" ", "-").slice(20));
  };

  const navigateToProject = () => {
    navigate(`/p/${project?.permalink}`);
  };

  const handleCopyPermalink = () => {
    clipboard.copy("https://www.synqup.com.au/p/" + project?.permalink);
    setCopied(true);
  };

  return (
    <div className="container">
      <Helmet>
        <title>Create Project - Synqup</title>
      </Helmet>

      <ProjectCover form={form} />

      <div className="flex items-center gap-6 mt-16">
        <UploadImageModal
          targetWidth={1280}
          targetHeight={1280}
          onUpload={handleUpload}
          projectId={project?.id}
          dataTestId="project-album-art"
        >
          <Image
            className="w-24 h-auto"
            radius="md"
            src={form.values.albumArt || "/images/no-album-art.png"}
            withPlaceholder
          />
        </UploadImageModal>

        <Textarea
          data-testid="project-title"
          data-quick-assist-id="project-title"
          {...form.getInputProps("name")}
          classNames={{
            root: "flex-1",
            input: `w-full font-bold text-5xl pl-2 -mx-2 bg-dark-1000 bg-opacity-0 border-none`,
          }}
          styles={{
            input: { border: "1px solid transparent" },
          }}
          placeholder="Untitled"
          autosize
          minRows={1}
        />
      </div>

      <h3 className="mt-8 mb-2">
        Add your demo{" "}
        <span className="font-normal text-gray-500">(optional)</span>
      </h3>

      <ProjectMixdown form={form} />

      <h3 className="mt-8">
        Who is/are the artists?{" "}
        <span className="font-normal text-gray-500">(optional)</span>
      </h3>

      <Input {...form.getInputProps("artist")} data-testid="project-artist" />

      <h3 className="mt-8">
        Give your project a unique url{" "}
        <span className="font-normal text-gray-500">(optional)</span>
      </h3>

      <Input
        {...form.getInputProps("permalink")}
        data-testid="project-permalink"
        data-quick-assist-id="project-permalink"
        value={`www.synqup.com.au/p/${form.values.permalink}`}
        onChange={handlePermalink}
      />

      {!user && (
        <Alert
          data-testid="project-create-guest-alert"
          icon={<Warning />}
          color="yellow"
          className="my-4 py-6"
          title={
            <span className="text-lg">
              Your project will automatically expire in 7 days
            </span>
          }
        >
          <span className="text-md">
            <a href={LOGIN_URL} className="text-white underline">
              Create your account or log in
            </a>{" "}
            to save your project, add collaborators, compare mix versions,
            adjust its privacy settings and more.
          </span>
        </Alert>
      )}

      <FormActionButtons
        data-testid="project-create"
        data-quick-assist-id="project-create"
        form={form}
        onSave={handleSubmit}
        loading={form.loading}
      />

      <Modal
        title={
          <div className="flex items-center gap-4">
            <h2>{project?.name} has been published!</h2>
            <span>🎉</span>
          </div>
        }
        opened={!!project}
        onClose={navigateToProject}
        size="sm"
        withCloseButton
      >
        <div className="flex items-center gap-8 my-6">
          <Image
            width={72}
            height={72}
            radius="md"
            src={project?.albumArt || "/images/no-album-art.png"}
            withPlaceholder
          />

          <div>
            <h3 data-testid="modal-project-title">{project?.name}</h3>
            <p data-testid="modal-project-artist">{project?.artist}</p>
          </div>
        </div>

        <div className="flex items-start gap-4">
          <Input
            readOnly
            className="flex-1"
            label="Link to your project"
            data-testid="modal-project-permalink"
            value={`www.synqup.com.au/p/${project?.permalink}`}
          />

          <Tooltip
            className="mt-10"
            label={copied ? "Link copied ✓" : "Copy permalink to clipboard"}
            positionDependencies={[copied]}
          >
            <ActionIcon
              variant="light"
              color="green"
              onClick={handleCopyPermalink}
            >
              <Clipboard />
            </ActionIcon>
          </Tooltip>
        </div>

        <div className="flex items-center justify-end mt-6">
          <Button
            data-testid="open-project"
            onClick={navigateToProject}
            rightIcon={<ChevronRight className="w-5 h-5" />}
          >
            Open
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export { ProjectCreate };
