import dayjs from "dayjs";
import React, { forwardRef } from "react";
import {
  DatePicker as MantineDatePicker,
  DatePickerProps,
} from "@mantine/dates";
import { getInputDefaults } from "./Input";

export const todayRenderer = (date) => {
  const sameDay = dayjs(date).isSame(new Date(), "date");
  return (
    <div className="relative">
      <div
        className={
          sameDay
            ? "absolute w-full h-full bg-amber-500 bg-opacity-20 rounded"
            : ""
        }
      />
      {date.getDate()}
    </div>
  );
};

const DatePicker = forwardRef<
  HTMLInputElement,
  DatePickerProps & {
    tooltip?: string;
  }
>((props, ref) => {
  const defaults: DatePickerProps = getInputDefaults(props);

  return (
    <MantineDatePicker
      ref={ref}
      renderDay={todayRenderer}
      withinPortal
      transition="fade"
      {...defaults}
      {...props}
      className={defaults.className}
    />
  );
});

export { DatePicker };
